import React, { useState } from 'react';
import { WindowButton } from './buttons/buttons';
import emailjs from 'emailjs-com';

function Mail() {
  const [subject, setSubject] = useState('');
  const [from, setFrom] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState(''); // State to hold error messages
  const [success, setSuccess] = useState(''); // State to hold error messages

  const handleSubmit = (e) => {
    e.preventDefault();

    // Check if all fields are filled
    if (!subject || !from || !message) {
      setError('Please fill out all fields before sending.'); // Set error message
      setSuccess('');
      // Clear the error after 3 seconds
      setTimeout(() => setError(''), 3000);
      return; // Stop form submission if any field is empty
    }

    // Clear error if all fields are filled
    setError('');

    // Define email parameters
    const templateParams = {
      subject: subject,
      from_email: from,
      message: message,
      to_name: 'Morgan Burlin',
    };

    emailjs.send('service_55qk1pd', 'template_12ueo5g', templateParams, 'DyjaOCZ7eHc-uyhhG')
      .then((response) => {
        setSuccess('Email sent successfully!');
        // Reset form fields after successful submission
        setSubject('');
        setFrom('');
        setMessage('');
      })
      .catch((error) => {
        console.error('Failed to send email:', error);
        setError('There was an error sending the email.'); // Set error message
        // Clear the error after 3 seconds
        setTimeout(() => setError(''), 3000);
      });
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <div className="form-header">
          <strong>New Message</strong>
          <WindowButton otype="submit">Send</WindowButton>
        </div>
        <div className="form-row">
          <label className="form-label">To:</label>
          <span className="prefilled-field">Morgan</span>
        </div>
        <div className="form-row">
          <label className="form-label">Subject:</label>
          <input
            type="text"
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
            className="form-input"
          />
        </div>
        <div className="form-row">
          <label className="form-label">From:</label>
          <input
            type="text"
            value={from}
            onChange={(e) => setFrom(e.target.value)}
            className="form-input"
          />
        </div>
        <div className="form-row">
          <textarea
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            className="message-body"
          />
        </div>
        {error && <div className="error-message">{error}</div>}
        {success && <div className="success-message">{success}</div>} 
      </form>
    </div>
  );
}

export default Mail;
