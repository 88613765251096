import React from 'react';
import './window/window.css';
import bullar from '../files/fikaspelet/bullar.png';
import buns from '../files/fikaspelet/buns.png';
import moose from '../files/fikaspelet/moose.png';
import obstacles from '../files/fikaspelet/obsticles.png';

function Fikaspelet() {

    return (
      <div className='Indent-Content'>
          <h1 className="h1-Big">Fikaspelet</h1>
          <h2 className="h2-bold">
          <a href="https://husmanspel.se/" target="_blank" rel="noopener noreferrer">husmanspel.se</a>
          </h2>
          <h2 className="h2-normal">
          In collaboration with <strong>Svensk Husman</strong>, we were given the task of developing a game with the aim of marketing to new customers and running a competition in between for wins and discount codes.
          <br></br>
          So we developed <strong>Fikaspelet</strong>.
          A web game in which you control a skiing bun between trees and rocks in an attempt to collect points and find discounts.
          </h2>
          <h2 className="h2-light">
          The game is developed with Phaser and the Web application is developed as an ASP.NET Core application with React which together with an ASP.NET Core web API and MySQL database builds up the entire system.
          </h2>
          <div className="container">
              <img src={bullar} alt="character in different angles" className="responsive-image" />
              <img src={buns} alt="buns to collect in game" className="responsive-image" />
              <img src={moose} alt="moose obstacles in the game" className="responsive-image" />
              <img src={obstacles} alt="obstacles in the game" className="responsive-image" />
          </div>
        </div>
    );
}

export default Fikaspelet;
