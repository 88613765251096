import React from 'react';

function Welcome() {

    return (
        <div className='Indent-Content'>
              <strong>W</strong>elcome to my <strong>Portfolio</strong>! :)
        </div>
    );
}

export default Welcome;
