import React, { useState, useEffect } from 'react';
import './App.css';
import Aboutme from './components/aboutme';
import Resume from './components/resume';
import Mail from './components/mail';
import Welcome from './components/welcome';
import Fikaspelet from './components/fikaspelet';
import WindowComponent from './components/window/window';
import { FooterButton, DeskApp } from './components/buttons/buttons'; // Import the FooterButton
import Taskbar from './components/taskbar'; // Import the TaskbarMenu component

// Import icons for the application buttons
import aboutmeIcon from './files/icons/aboutme.png'; 
import resumeIcon from './files/icons/resume.png';
import mailIcon from './files/icons/mail.png';
import startIcon from './files/icons/start2.png';
import lussbulle from './files/icons/fikaspelet.png';

function App() {
  const [windows, setWindows] = useState([
    { id: 1, title: 'About Me', icon: aboutmeIcon, component: <Aboutme />, position: { x: 50, y: 50 }, isMinimized: false, isFocused: true, isClosed: true },
    { id: 2, title: 'Resume', icon: resumeIcon, component: <Resume />, position: { x: 55, y: 60 }, isMinimized: false, isFocused: false, isClosed: true },
    { id: 3, title: 'Mail', icon: mailIcon, component: <Mail />, position: { x: 45, y: 40 }, isMinimized: false, isFocused: false, isClosed: true },
    { id: 4, title: 'Welcome', component: <Welcome />, position: { x: 50, y: 50 }, isMinimized: false, isFocused: true, isClosed: false, hideInTaskbar: true, hideOnDesktop: true },
    { id: 5, title: 'Fikaspelet', icon: lussbulle, component: <Fikaspelet />, position: { x: 55, y: 60 }, isMinimized: false, isFocused: false, isClosed: true },
  ]);
  
  const [menuOpen, setMenuOpen] = useState(false); // State to track the drop-up menu
  const [isStartFocused, setIsStartFocused] = useState(false); // State to track the "Start" button focus
  const [selectedIcon, setSelectedIcon] = useState(null); // State to track the selected icon

  // Function to focus only the clicked window and unfocus others
  const focusWindow = (id) => {
    setWindows((prevWindows) =>
      prevWindows.map((win) => ({
        ...win,
        isFocused: win.id === id, // Only the clicked window is focused
      }))
    );
  };

  const toggleMinimize = (id) => {
    setWindows((prevWindows) =>
      prevWindows.map((win) =>
        win.id === id ? { ...win, isMinimized: !win.isMinimized, isFocused: false } : win
      )
    );
  };

  // Restore a window and focus it while ensuring only one window is focused
  const restoreWindow = (id) => {
    setWindows((prevWindows) =>
      prevWindows.map((win) =>
        win.id === id
          ? { ...win, isMinimized: false, isFocused: true, isClosed: false } // Focus the restored window
          : { ...win, isFocused: false } // Unfocus all others
      )
    );
  };
  
  const openWindow = (id) => {
    setWindows((prevWindows) =>
      prevWindows.map((win) =>
        win.id === id ? { ...win, isClosed: false, isFocused: true, isMinimized: false } : { ...win, isFocused: false }
      )
    );
  };

  // Close window and remove it from both the display and the footer
  const closeWindow = (id) => {
    setWindows((prevWindows) =>
      prevWindows.map((win) =>
        win.id === id ? { ...win, isClosed: true, isMinimized: false } : win
      )
    );
  };

  // Handle click on the desktop icon
  const handleIconClick = (id) => {
    setSelectedIcon(id); // Set the currently selected icon
  };

  const handleClickOutside = (event) => {
    // Check if the click happens inside the desktop icons
    if (event.target.closest('.desktop-icons')) return; 
    
    // Check if the click happens inside the taskbar menu or Start button
    if (event.target.closest('.App-footer') || event.target.closest('.taskbar-menu')) return;
  
    // If clicked outside both, close the menu and reset any selections
    setSelectedIcon(null);
    setMenuOpen(false); // Close the taskbar menu when clicking outside
    setIsStartFocused(false); // Remove focus from Start button
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  // Toggle taskbar menu and update the "Start" button focus state
  const toggleTaskbarMenu = () => {
    setMenuOpen((prevState) => !prevState);
    setIsStartFocused((prevState) => !prevState); // Toggle focus for "Start" button
  };

  return (
    <div className="App">
      <div className="App-body">
        <div className="desktop-icons">
          {windows
            .filter((win) => !win.hideOnDesktop) // Exclude Welcome window from desktop
            .map((win) => (
              <DeskApp
                key={win.id} // Add a unique key for each DeskApp
                icon={win.icon}
                label={win.title} // Use the title from the windows state
                onDoubleClick={() => openWindow(win.id)}
                onClick={() => { handleIconClick(win.id); }} // Handle icon click
                onTouchStart={() => openWindow(win.id)} // Add touch support
                isSelected={selectedIcon === win.id} 
              />
            ))}
        </div>

        {/* Render windows that are not closed */}
        {windows.map((win, index) => (
          !win.isMinimized && !win.isClosed && (
            <WindowComponent
              key={win.id}
              title={win.title}
              isFocused={win.isFocused}
              position={win.position} 
              onMinimize={() => toggleMinimize(win.id)}
              onFocus={() => focusWindow(win.id)}
              onClose={() => closeWindow(win.id)} // Close the window when the close button is clicked
              zIndex={index + 1} // Set zIndex to ensure focused window comes to front
            >
              {win.component}
            </WindowComponent>
          )
        ))}
      </div>

      <footer className="App-footer">
        {/* Taskbar Button always on the left */}
        <FooterButton
            key={0}
            title="Start"
            icon={startIcon}
            onClick={toggleTaskbarMenu} // Toggle the menu when clicking the "Start" button
            isFocused={isStartFocused} // Apply the focused styling based on the state
        />
        {windows
          .filter((win) => !win.isClosed) // Exclude Welcome window from taskbar
          .map((win) => (
            <FooterButton
              key={win.id}
              title={win.title}
              icon={win.icon}
              onClick={() => restoreWindow(win.id)} // Restore and focus window when clicked
              isFocused={win.isFocused && !win.isMinimized} // Highlight when focused
            />
          ))}

        {menuOpen && (
          //<Taskbar windows={windows} onRestoreWindow={restoreWindow} />
          <Taskbar
            windows={windows.filter((win) => !win.hideInTaskbar)} // Filter to include only windows that are not closed and not hidden in the taskbar
            onRestoreWindow={restoreWindow}
          />
        )}
      </footer>
    </div>
  );
}

export default App;
