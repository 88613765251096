import React from 'react';
import { WindowButton } from './buttons/buttons'; // Adjust the path as necessary

function Resume() {
  // Function to handle the download
  const handleDownload = () => {
    const link = document.createElement('a'); // Create a link element
    link.href = '/resume-morgan.pdf'; // Now point to the public folder
    link.download = 'resume-morgan.pdf'; // Set the file name for download
    document.body.appendChild(link); // Append to the body
    link.click(); // Programmatically click the link to trigger the download
    document.body.removeChild(link); // Remove the link after downloading
  };

  return (
    <div >
      <h2 className="h2-bold">resume-morgan.pdf</h2>
      <WindowButton onClick={handleDownload}>Download</WindowButton>

      <div className="pdf-viewer">
        <iframe 
          src="/resume-morgan.pdf#zoom=40" // Path to your PDF file
          width="100%"
          height="100%"
          style={{ border: 'none'}} // Hide scrollbars
          title="Resume PDF"
        />
      </div>
    </div>
  );
}

export default Resume;
