import React, { useState, useRef, useCallback, useEffect } from 'react';
import Draggable from 'react-draggable'; // Import Draggable from react-draggable
import './window.css'; 
import { CloseButton, MinimizeButton, MaximizeButton } from '../buttons/buttons'; // Import your custom buttons

const WindowComponent = ({ position: initialPosition, title, children, isFocused, onMinimize, onFocus, onClose, zIndex }) => {
  const [isMaximized, setIsMaximized] = useState(false);
  const [position, setPosition] = useState(initialPosition); // Use initialPosition from props
  const windowRef = useRef(null); // Reference for the actual window DOM node
  
  // Effect to handle changes to initialPosition from props (e.g., when coming from App.js)
  useEffect(() => {
    setPosition(initialPosition);
  }, [initialPosition]);

  // Toggle the maximize state of the window
  const toggleMaximize = () => {
    if (!isMaximized) {
      if (windowRef.current) {
        const rect = windowRef.current.getBoundingClientRect(); // Get its current position and size
        setPosition({ x: rect.left, y: rect.top }); // Store the position before maximizing
      }
    }
    setIsMaximized(!isMaximized); // Toggle maximized state
  };


  // Callback to track the drag position
  const onControlledDrag = useCallback((e, data) => {
    setPosition({ x: data.x, y: data.y });
  }, []);

  // Called when dragging stops to ensure the window is within bounds
  const onStopDrag = useCallback(() => {
    const windowElement = windowRef.current;
    if (windowElement) {
      const rect = windowElement.getBoundingClientRect();
      const { innerWidth, innerHeight } = window;

      const buffer = 20; // Minimum buffer to ensure some part of the window stays visible
      let newX = position.x;
      let newY = position.y;

      if (rect.left < buffer) {
        newX = buffer;
      } else if (rect.right > innerWidth - buffer) {
        newX = innerWidth - rect.width - buffer;
      }

      if (rect.top < buffer) {
        newY = buffer;
      } else if (rect.bottom > innerHeight - buffer) {
        newY = innerHeight - rect.height - buffer;
      }

      setPosition({ x: newX, y: newY });
    }
  }, [position]);

  return (
    <Draggable
      handle=".window-header"
      position={isMaximized ? { x: 0, y: 0 } : position} // Set position to (0,0) when maximized
      onDrag={onControlledDrag} // Track the drag position
      onStop={onStopDrag} // Ensure window stays within bounds after dragging
      onStart={onFocus} // Ensure that clicking on the window brings it to focus
      nodeRef={windowRef}
      disabled={isMaximized} // Disable dragging when maximized
    >
      <div
        className={`window ${isMaximized ? 'maximized' : ''} ${isFocused ? 'focused' : ''}`}
        ref={windowRef}
        style={{ zIndex: isFocused ? zIndex : 'auto' }} // Set zIndex to bring focused window to front
      >
        <div className={`window-header ${isFocused ? 'focused' : ''}`} onClick={onFocus}>
          <div className="window-title">{title}</div>
          <div className="window-controls">
            <MinimizeButton onClick={onMinimize} />
            <MaximizeButton onClick={toggleMaximize} />
            <CloseButton onClick={onClose} />
          </div>
        </div>

        <div className="window-body" onMouseDown={onFocus}>
          {children}
        </div>
      </div>
    </Draggable>
  );
};

export default WindowComponent;
