import React from 'react';
import './buttons.css';

// Importing PNG icons
import closeIcon from '../../files/icons/close.png';
import minimizeIcon from '../../files/icons/minimize.png';
import fullscreenIcon from '../../files/icons/fullscreen.png';

// A generic button component that can render icons
const Button = ({ onClick, icon, altText, style }) => {
  const handleTouchStart = (e) => {
    e.preventDefault();
    onClick();
  };

  return (
    <button
      onClick={onClick}
      onTouchStart={handleTouchStart} // Add touch event listener
      style={style}
    >
      <img src={icon} alt={altText} className="button-icon" />
    </button>
  );
};


// Predefined buttons with icons
export const CloseButton = ({ onClick }) => (
  <Button onClick={onClick} onTouchStart={onClick} icon={closeIcon} altText="Close" />
);

export const MinimizeButton = ({ onClick }) => (
  <Button onClick={onClick} onTouchStart={onClick} icon={minimizeIcon} altText="Minimize" />
);

export const MaximizeButton = ({ onClick }) => (
  <Button onClick={onClick} onTouchStart={onClick} icon={fullscreenIcon} altText="Maximize" />
);


//Button that exist inside windows
export const WindowButton = ({ onClick, children }) => (
  <button onClick={onClick} className="window-button" aria-label="Window Button">
    {children} 
  </button>
);


// Footer button to restore/minimize windows
export const FooterButton = ({ onClick, title, icon, isFocused }) => (
  <button
    className={`footer-button ${isFocused ? 'pressed' : 'normal'}`}
    onClick={onClick}
  >
    <img src={icon} className="footer-button-image" />
    {title}
  </button>
);


export const DeskApp = ({ onClick, onDoubleClick, icon, label, isSelected }) => {
  return (
    <div
      className={`app-icon ${isSelected ? 'selected' : ''}`} // Apply selected class based on isSelected prop
      onClick={onClick}
      onDoubleClick={onDoubleClick}
      onTouchStart={onDoubleClick}
    >
      <img src={icon} alt={label} className="app-icon-image" />
      <div className="app-icon-label">{label}</div>
    </div>
  );
};