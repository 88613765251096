// TaskbarMenu.js
import React from 'react';
import linkedinIcon from '../files/icons/linkedIn.png'; // Import the LinkedIn logo

const Taskbar = ({ windows, onRestoreWindow }) => {
  return (
    <div className="taskbar-menu">
      {/* Left Column for aesthetic */}
      <div className="taskbar-menu-left-column">
        <div className="taskbar-left-inner-column"></div>
      </div>

      {/* Right Column with Taskbar Items */}
      <div className="taskbar-menu-right-column">
        {windows.map((win) => (
          <div key={win.id} className="taskbar-menu-item" onClick={() => onRestoreWindow(win.id)}>
            <img src={win.icon} alt={win.title} className="taskbar-menu-icon" />
            <span className="taskbar-menu-text">{win.title}</span>
          </div>
        ))}
        <hr />
        {/* LinkedIn Link */}
        <div className="taskbar-menu-item">
          <a href="https://www.linkedin.com/in/morgan-burlin-160703220/" target="_blank" rel="noopener noreferrer" className="taskbar-link">
            <div className="taskbar-link-content"> 
              <img src={linkedinIcon} alt="LinkedIn" className="taskbar-menu-icon" />
              <span className="taskbar-menu-text">LinkedIn</span>
            </div>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Taskbar;
